<template>
  <div style="background: #F8FAFC" v-loading="PageLoadingShow">
    <div style="display: flex; align-items: center; position: relative; justify-content: center; width: 100%; margin-bottom: 20px">
   
      <div style="margin-top: 30px; font-size: 18px; font-weight: 500; color: #000">{{ $t('lang.b1') }}</div>
      <div></div>
    </div>
    <div class="shequ">
      <doExchangeVue v-loading="LoadingShow" :exchangeList="exchangeList" @change="change" @doExchange="doExchange" @approve="approve" v-if="team_level<0" :allowance="allowance"></doExchangeVue>
      <div class="my-swipe" v-else>
        <template v-if="exchangeList.length!=0">
          <div class="my-swipe-item" :style="`background: url(${testlist[team_level].bgcard}) no-repeat;`">
          <div class="item-title">{{$t('lang.d213')}}</div>
          <div class="item-rate" :style="`color: ${testlist[team_level].color} ;`">BEBS</div>
          <div class="item-text" :style="`color: ${testlist[team_level].color} ;`">{{ exchangeList[team_level].exchangeNum }}</div>
          <div class="item-level" :style="`color: ${testlist[team_level].color} ;`">{{ $t(testlist[team_level].level )  }}</div>
         
      
        </div>
        </template>
       
      </div>
      <!-- <van-swipe @change="changeswipe" class="my-swipe" :show-indicators="false" indicator-color="white" :loop="false">
        <van-swipe-item v-for="(item, index) in testlist" :key="index"> -->

      <!-- </van-swipe-item>
      </van-swipe> -->

      <p class="s_title">{{ $t('lang.d62') }}</p>
      <div class="yaoq" v-loading="bindaddrLoadingShow">
        <div class="y_title">{{ $t('lang.b2') }}</div>
        <div class="y_line1" v-if="recommender != ''">
          <span>{{ linkshow }}</span>
        </div>
        <div class="y_line1" v-else style="padding-left: 10px;">
          <input type="text" v-model="fromInput"  :placeholder="$t('lang.b22')" style="border: none;width: 100%;">
        </div>
        <div class="y_button copys" @click="copy" :data-clipboard-text="linkCopy" v-if="recommender != ''">{{ $t('lang.d64') }}</div>
        <div class="y_button" @click="bindaddr" v-else>{{ $t('lang.b3') }}</div>
       
      </div>

      <div class="jilu" v-if="recommender!=''">
        <p class="j_title">{{ $t('lang.d65') }}</p>
        <div class="j_text">{{ ComRecommender }}</div>

       
       
      </div>
      <van-popup v-model="popupshow" class="popup" round>
      
        <div class="p_main" v-loading="bindaddrLoadingShow">
          <p class="m_title">{{ $t('lang.d76') }}</p>
          <span>{{ $t('lang.b4') }}</span>
          <van-field type="text" v-model="fromInput" input-align="left" placeholder="" class="van-field" />
          <div class="m_button" @click="bindaddr">{{ $t('lang.d78') }}</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
  
<script>
import AbiAddress from './config';
import  BEBSABI from '@/config/abi/BEBS.json'
import ERC20ABI from '@/config/abi/erc20.json'
import Web3 from 'web3';
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import doExchangeVue from './doExchange.vue';
import { MaxUint256 } from '@ethersproject/constants';
export default {
  data() {
    return {
      PageLoadingShow:false,
      LoadingShow:false,
      bindaddrLoadingShow:false,
      popupshow: false,
      recommender:'',
      fromInput:'',
      allowance:0,
      exchangeList: [],
      exchangeItem:{},
      team_level:0,
        userInfoNum:0,
      testlist: [
        {
          bgcard: require('@/assets/imgsnowball/level1.png'),
          color: '#8568D0',
          level: 'lang.b5',
        },
        {
          bgcard: require('@/assets/imgsnowball/level2.png'),
          color: '#44B2CB',
          level: 'lang.b6',
        },
        {
          bgcard: require('@/assets/imgsnowball/level3.png'),
          color: '#F1A560',
          level: 'lang.b7',
        }
      
      ],
    };
  },

  created() {
    if(this.account){
      this.init();
    }
   
  },
  mounted() {},
  watch: {
    account() {
      //获取账号
      this.init();
    },
    lang() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
    linkCopy(){
      return `${AbiAddress.HTTP_URL}/#/?invite_addr=${this.account}`
    },
    linkshow() {
      var link = `${AbiAddress.HTTP_URL}/#/?invite_addr=${this.account}`;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
    ComRecommender (){
      var link = this.recommender;
      const head = link.slice(0, 22);
      const tail = link.slice(link.length - 5);
      return head + '****' + tail;
    },
  },

  methods: {
    Fmgval(index) {
      return ['lang.swap79', 'lang.d217', 'lang.d218', 'lang.d219'][index];
    },
    async init(){
      this.PageLoadingShow = true
    
      this.getAllowance()
        await this.getipoList()
        await this.getUserInfo()
        await this.getRecommender()
        this.$nextTick(()=>{
          this.PageLoadingShow = false
        })
    },
    change(val){
       this.exchangeItem = val
    },

    locationHref(){
      if(this.recommender!='') return ;
       let url =window.location.href;
       console.log(url);
       let obj = url.split('?invite_addr=')[1]//截取问号后面参数
       console.log(obj);
       if(obj!=""&&obj!=null&&obj!=undefined){
        console.log(222);
         this.fromInput = obj
         this.popupshow = true
       }
    },
    async approve(){
      let web3 = new Web3(window.ethereum);
      const erc20Contract = new web3.eth.Contract(ERC20ABI, AbiAddress.USDT_ADDRESS)
      console.log('erc20Contract',erc20Contract);
      let gas = 0
      try {
         gas = await erc20Contract.methods.approve(AbiAddress.BEBS_ADDRESS, MaxUint256.toString()).estimateGas({ from: this.account })
      } catch (error) {
        return this.$toast(this.$t('lang.b8')) ;
      }
       this.LoadingShow = true
       this.$toast(this.$t('lang.b9'))
      erc20Contract.methods
        .approve(AbiAddress.BEBS_ADDRESS, MaxUint256.toString())
        .send({
          from: this.account,
          gas
        })
        .on('receipt', res => {
            this.getAllowance()
            this.LoadingShow = false
            this.$toast(this.$t('lang.b10'))
        })
        .on('error', (error, receipt) => {
          this.$toast(this.$t('lang.b8'))
          this.LoadingShow = false
        });
    },
    async doExchange(){
      if( Object.keys(this.exchangeItem).length === 0) return this.$toast(this.$t('lang.b12'));
      let web3 = new Web3(window.ethereum);
      let bebsContract = new web3.eth.Contract(BEBSABI,AbiAddress.BEBS_ADDRESS);
      let gas = 0
      try {
         gas = await bebsContract.methods.doIPO(this.exchangeItem.index).estimateGas({ from: this.account })
      } catch (error) {
        return this.$toast(this.$t('lang.b13')) ;
      }
      this.LoadingShow = true
      this.$toast(this.$t('lang.b9'))
      bebsContract.methods.doIPO(this.exchangeItem.index).send({
             from:this.account,
            gas
      }).on('receipt',res=>{
        this.getUserInfo()
         this.$toast(this.$t('lang.b14'))
         this.LoadingShow = false
           console.log('res',res);
      }).on('error',(error, receipt) =>{
            console.log('err',receipt);
            this.LoadingShow = false
            this.$toast(this.$t('lang.b13'))
      })
    },
    async bindaddr(){
      let web3 = new Web3(window.ethereum);
      let bebsContract = new web3.eth.Contract(BEBSABI,AbiAddress.BEBS_ADDRESS);
      let gas = 0
      try {
         gas = await bebsContract.methods.bind(this.fromInput).estimateGas({ from: this.account })
      } catch (error) {
        return this.$toast(this.$t('lang.b15')) ;
      }
      this.bindaddrLoadingShow = true
      this.$toast(this.$t('lang.b9'))
      bebsContract.methods.bind(this.fromInput).send({
             from:this.account,
             gas
      }).on('receipt',res=>{
        this.popupshow  = false
        this.getRecommender()
        this.$toast(this.$t('lang.b16'))
           console.log('res',res);
           this.bindaddrLoadingShow = false
      }).on('error',(error, receipt) =>{
        this.$toast(this.$t('lang.b15'))
            console.log('err',error);
            this.bindaddrLoadingShow = false
      })
    },
    async getAllowance(){
   
         
      let web3 = new Web3(window.ethereum);
      const erc20Contract = new web3.eth.Contract(ERC20ABI, AbiAddress.USDT_ADDRESS)
      this.allowance = await erc20Contract.methods.allowance(this.account, AbiAddress.BEBS_ADDRESS).call();
                console.log(this.allowance);
    },

    async getRecommender (){
      let web3 = new Web3(window.ethereum);
      let bebsContract = new web3.eth.Contract(BEBSABI,AbiAddress.BEBS_ADDRESS);
      let addr = await bebsContract.methods.recommender(this.account).call()
      if(addr == 0x0000000000000000000000000000000000000000){
         this.recommender = ''
      }else{
        this.recommender = addr
      }
      this.locationHref()
    },
    async getUserInfo (){
      let web3 = new Web3(window.ethereum);
      let bebsContract = new web3.eth.Contract(BEBSABI,AbiAddress.BEBS_ADDRESS);
      let Num = await bebsContract.methods.userInfo(this.account).call()
      console.log('Num',Num);
      this.userInfoNum = web3.utils.fromWei(Num.ipoAmount);
      this.team_level = this.exchangeList.findIndex(item => item.value == this.userInfoNum)
      console.log('this.team_level',this.team_level);
    },
    async getipoList (){
      this.exchangeList = []
      let web3 = new Web3(window.ethereum);
      let bebsContract = new web3.eth.Contract(BEBSABI,AbiAddress.BEBS_ADDRESS);
       let ipolen = await bebsContract.methods. ipoArrLen().call()
       console.log('ipolen',ipolen);
       for(let i = 0;i<ipolen;i++){
         let ipo = await bebsContract.methods.ipoArr(i).call()
           this.exchangeList.push({
                 index:i,
                 value: web3.utils.fromWei(ipo.amount),
                 exchangeNum:web3.utils.fromWei(ipo.amount) * (ipo.exchangeRate/10000),
                 WeiAmount:ipo.amount,
                 exchangeRate:ipo.exchangeRate/10000,
                 name: 'USDT',
                 logo: require('@/assets/img/logo/USDT.png'),
           })
           

       }
       console.log('this.exchangeList',this.exchangeList);
    },
    popupfunopen(){

    },
    popupfunclose(){
        this.popupshow = false
    },
    copy() {
     
      this.invite_link = this.account;
      if (this.invite_link) {
        var clipboard = new Clipboard('.copys');
        clipboard.on('success', e => {
          this.$toast(this.$t('lang.swap300'));
          clipboard.destroy(); // 释放内存
        });
        clipboard.on('error', e => {
          this.$toast(this.$t('lang.swap301'));
          clipboard.destroy(); // 释放内存
        });
      }
    },

    
  },
  components: {
    doExchangeVue
  },
};
</script>
  
<style lang="less" scoped>
.sipper2 {
  margin-top: 160px;
  width: 100%;
  .sipper-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 500;
  }
  .sipper-button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #ae47fd;
    background: #fff;
    position: relative;
  }
  .sipper-button-abs {
    position: absolute;
    width: 50px;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #477bfd;
  }
}
.sipper {
  margin-top: 20px;
  width: 100%;
  height: 201px;
  border-radius: 16px;
  opacity: 1;

  box-sizing: border-box;
  border: 1px solid #dedede;
  padding: 18px 20px;
  .sipper-button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #ae47fd;
  }
  .sipper-line {
    margin-bottom: 13px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .line-left {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;

      font-feature-settings: 'kern' on;
      color: #3d3d3d;
    }
    .line-right {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0px;

      font-feature-settings: 'kern' on;
      color: #477bfd;
    }
  }
}
.my-swipe {
  width: 100%;
  .my-swipe-item {
    display: flex;
    flex-direction: column;
    padding: 21px;
    position: relative;
    width: 100%;
    height: 180px;
    background: url(../../../assets/imgsnowball/card1.png) no-repeat;
    background-size: contain !important;
  }
  .item-title {
    position: absolute;
    left: 19px;
    top: 21px;
    font-size: 18px;
    font-weight: bold;
  }
  .item-rate {
    position: absolute;
    left: 58px;
    top: 68px;
    font-size: 24px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0px;
   
   
  }
  .item-text {
    width: 110px;
    position: absolute;
    left: 19px;
    top: 104px;
    font-size: 40px;
    font-weight: bold;
   
  }
  .item-level {
   width: 150px;
    text-align: center;
    position: absolute;
    right: 5px;
    top: 128px;
    font-family: YouSheBiaoTiHei;
    font-size: 18px;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: 0px;

    color: #477bfd;
  }
}
.shequ {
  width: 375px;
  border-radius: 20px 20px 0 0;
  padding: 20px;

  background: #fff;

  .s_title {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Poppins';
  }

  .vvs {
    width: 100%;
    overflow-x: scroll;
    display: flex;

    .vv {
      flex-shrink: 0;
      width: 100%;
      height: 200px;

      border-radius: 14px;
      background-size: cover !important;
      margin-right: 30px;
      position: relative;

      .biao {
        width: 55px;
        height: 25px;
        border-radius: 46px 46px 56px 46px;
        color: #000;
        font-size: 10px;
        font-weight: 700;
        font-family: 'PingFang';
        text-align: center;
        line-height: 25px;
        position: absolute;

        top: 25px;
        left: 30px;
      }

      .text {
        position: absolute;
        bottom: 20px;
        left: 30px;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70px;
      }
    }
  }
}

.yaoq {
  width: 100%;
  margin: 0 auto;

  padding: 20px;
  border-radius: 20px;
  opacity: 1;
  background: #f7f7f7;
  box-shadow: 0 0 12px 0 #f7f7f7;

  .y_line1 {
    width: 100%;
    height: 52px;
    border-radius: 10px;
    text-align: center;
    line-height: 52px;
    background: #ffffff;
    font-size: 12px;
    color: #000;
  }

  .y_title {
    opacity: 1;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    margin-bottom: 20px;
  }

  .y_button {
    margin-top: 30px;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    background: #4583ff;

    text-align: center;
    line-height: 52px;
    color: #ffffffff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.jilu {
  width: 100%;

  margin-top: 20px;

  .j_title {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .j_text {
    width: 100%;
    height: 59px;
    border-radius: 20px;
    background: #f7f7f7;
    box-shadow: 0 0 12px 0 #f7f7f7;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    text-align: center;
    line-height: 59px;
  }

  .j_sosuo {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .s_span {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}

.van-field {
  width: 228px;
  margin: 0 auto;

  height: 48px;
  border-radius: 4px;
  font-weight: bold;
  background: #f7f7f7 !important;
  padding: 14px !important;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #000;
    background: #f7f7f7 !important;
  }
}

.vant {
  width: 202px;
  height: 34px;
  position: relative;
  background: #f7f7f7;
  border-radius: 20px;

  .v_icon {
    margin-top: 12px;
    margin-left: 7px;
  }

  .van_sosuo {
    float: right;
    padding: 0;
    color: #fff;
    width: 170px;
    height: 34px;

    border: none;
    background: none;
    box-shadow: 0 0 12px 0 #080a1b1a;
  }
}

.y_cont {
  width: 100%;
  margin-top: 30px;

  .c_li {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
    padding: 10px;
  }
}

.popup {
 

  .p_main {
    width: 288px;
    height: 248px;
    border-radius: 10px;
    padding: 10px;
    background: #ffffffff;
  

    .m_button {
      width: 228px;
      height: 48px;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 20px;
      color: #ffffffff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
      line-height: 48px;
      background: #5c90fe;
    }

    .m_title {
      color: #2f272aff;
      font-size: 20px;
      font-weight: 700;
      font-family: 'PingFang';
      text-align: center;
    }

    span {
      color: #000000ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
      float: left;
      margin-bottom: 20px;
    }
  }
}

.popupx {
  width: 36px;
  height: 36px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.s_boxs {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px;

  background: #f7f7f7;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .b_title {
    color: #999999ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  .b_cont {
    color: #999999ff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: #f7f7f7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.van-field2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 !important;
  padding-top: 5px !important;
  color: #fff;
  width: 170px;
  height: 34px;

  border: none;
  background: none !important;
  box-shadow: 0 0 12px 0 #080a1b1a;

  /deep/ .van-field__control {
    font-size: 14px;
    color: #fff;
  }
}

/deep/.van-progress {
  width: 278px;
  height: 6px;
}

.rate {
  width: 55px;
  height: 25px;
  border-radius: 46px 46px 56px 46px;
  color: #ffffffff;
  font-size: 10px;
  font-weight: 700;
  font-family: 'PingFang';
  text-align: center;
  line-height: 25px;
  position: absolute;

  top: 65px;
  left: 110px;
}

.shichang {
  width: 100%;
  height: 155px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 21px 16px;
  background: #f7f7f7;
  box-shadow: 0 0 12px 0 #f7f7f7;

  .line {
    height: 49%;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .lin {
    width: 100%;
    height: 1px;
    background: #3b3c40;
    margin-bottom: 10px;
  }
}
</style>