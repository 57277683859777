<template>
  <div class="home_page">
    
    <Header2></Header2>
    <div class="explanation">
 
      <p>{{ $t('lang.swap277') }}</p>

      <p>{{ $t('lang.swap278') }}</p>
      <span>{{ $t('lang.swap279') }}</span>
    </div>
    <router-link to="footer/SwapIndex/Swap">
      <div class="inDapp">{{ $t('lang.swap337') }}</div>
    </router-link>
     
    <div class="route_map">
      <div class="map">
        <span>{{ $t('lang.swap280') }}：</span>
        <img src="@/assets/img/routemap.png"
             class="routemap_img">
      </div>
      <div class="map_list">
        <p>2023</p>
        <div>{{ $t('lang.swap375') }}</div>
        <div>{{ $t('lang.swap376') }} </div>
      </div>
      <div v-show="mapShow"
           class="map_list">
           <p>2024</p>
        <div>{{ $t('lang.swap378') }}</div>
        <div>{{ $t('lang.swap379') }}</div>
       
      </div>
      <div @click="mapclick"
           class="more">
        <span style="color: #1a72fdff;">{{ $t('lang.swap286') }}</span>
        <img src="@/assets/img/more_img.png"
             :style="{ 'transform': mapShow?'rotate(180deg)':''}"
             class="more_img">
      </div>
    </div>
  
    <div class="exhibit">
      <div class="hold">
        <span>{{ $t('lang.swap287') }}：{{myBalance.toFixed(6)}}</span>
      </div>
      <div style="display: flex;  justify-content: space-between; margin: 0 auto; width: 85%;">
        <div class="exhibit_li">
        <div class="li_top">
          <span>100 M</span>
        </div>
        <div class="li_button">
          <span>Total Supply</span>
        </div>
      </div>
      <div class="exhibit_li">
        <div class="li_top">
          <span>{{((D6price * 10000000)/100000).toFixed(2)}}M</span>
        </div>
        <div class="li_button">
          <span>Market cap</span>
        </div>
      </div>
      </div>
     
  
      <van-swipe class="specialmain" :autoplay="5000"  :show-indicators=false>
  <van-swipe-item>
     <div class="special">
       <div class="special_li" v-for="item in tokenqutoe" :key="item.token_id">
          <img class="sli_yuan" :src="`${tokenimgurls}${item.logo}`"/>
          <div class="slitext">
            <p class="wbnb">{{ item.token_name }} <span style=" color: #bf5874ff; font-size: 12px;">{{ item.quote_change }}</span></p>
            <P style="color: #fff;">${{ item.last }}</P>
          </div>
       </div>
      
      </div>
    </van-swipe-item>
  <van-swipe-item>
    <div class="special">
       <div class="special_li" v-for="item in tokenqutoe2" :key="item.token_id">
          <img class="sli_yuan" :src="`${tokenimgurls}${item.logo}`"/>
          <div class="slitext">
            <p class="wbnb">{{ item.token_name }} <span style=" color: #bf5874ff; font-size: 12px;">{{ item.quote_change }}</span></p>
            <P style="color: #fff;">${{ item.last }}</P>
          </div>
       </div>
      
      </div>
  </van-swipe-item>
 
</van-swipe>
     
   
    
    
      
    </div>

    <div class="universal_box merchandise_bg">
      <div class="clcaption">
        <span>{{ $t('lang.swap88') }}</span>
      </div>
      <div class="slogan">
        <span>{{ $t('lang.swap288') }} D6 Swap</span>
        <span>{{ $t('lang.swap289') }}</span>
      </div>
      <div @click="toSwap"
           class="box_button">
        <span>{{ $t('lang.swap88') }}</span>
        <img src="@/assets/img/box_arrow.png"
             class="box_arrow">
      </div>
    </div>
    <div class="universal_box earncoins_bg">
      <div class="clcaption">
        <span>{{ $t('lang.swap143') }}</span>
        <img src="@/assets/img/pledge.png"
             class="pledge_img">
      </div>
      <div class="slogan">
        <span>{{ $t('lang.swap290') }}</span>
        <span>{{ $t('lang.swap291') }}</span>
      </div>
      <div class="million">
        <span>TVL：$195 million</span>
      </div>
      <div 
           class="box_button">
        <span>{{ $t('lang.swap190') }}</span>
        <img src="@/assets/img/box_arrow.png"
             class="box_arrow">
      </div>
    </div>
    <div class="universal_box emission_bg">
      <div class="clcaption">
        <span>{{ $t('lang.swap292') }}</span>
      </div>
      <div class="slogan">
        <span>D6 Swap {{ $t('lang.swap293') }}</span>
        <span>{{ $t('lang.swap294') }}</span>
      </div>
      <div class="box_button">
        <span>{{ $t('lang.swap295') }}</span>
        <img src="@/assets/img/box_arrow.png"
             class="box_arrow">
      </div>
    </div>
    <div class="universal_box market_bg">
      <div class="clcaption">
        <span>{{ $t('lang.swap338') }}</span>
      </div>
      <div class="slogan">
        <span>{{ $t('lang.swap339') }}</span>
        
      </div>
      <div class="box_button">
        <span>{{ $t('lang.swap298') }}</span>
        <img src="@/assets/img/box_arrow.png"
             class="box_arrow">
      </div>
    </div>
    <div class="universal_box super_bg">
      <div class="clcaption">
        <span>{{ $t('lang.swap340') }}</span>
      </div>
      <div class="slogan">
        <span>{{ $t('lang.swap341') }}</span>
        <span>{{ $t('lang.swap342') }}</span>
      </div>
      <div class="box_button">
        <span>{{ $t('lang.swap298') }}</span>
        <img src="@/assets/img/box_arrow.png"
             class="box_arrow">
      </div>
    </div>
    <div class="partner_list">
  
      <div class="partner">
        <div class="partner_title">
          <span>{{ $t('lang.swap164') }}</span>
        </div>
        <div class="partner_img">
          <img v-for="(item,index) of institutionsListB"
               :key="index"
               :src="item"
               class="img">
        </div>
      </div>
      <div class="partner">
        <div class="partner_title">
          <span>{{ $t('lang.swap165') }}</span>
        </div>
        <div class="partner_img">
          <div v-for="(item,index) of institutionsListC"
               :key="index"
              
               class="img" >
               <img   :src="item"/>
          </div>
         
        </div>
      </div>
      <div class="Icon_img">
        <img v-for="(item,index) of institutionsListIcon"
             :key="index"
             :src="item"
             class="img" />
      </div>
      <div class="reserved">
        <span>Copyright@2023 Super Swap-D6 .  All rights reserve</span>
      </div>
    </div>
  </div>
</template>

<script>
import Header2 from '@/components/Header2.vue';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { mapState } from 'vuex';
import { invite,homedata, user,bizhong, moneymask } from '../../api/index';
import lp from '@/methods/lp.js';

export default {
  data () {
    return {
     
      D6price:0,
      death: 0,
      myBalance: 0,
      chainInviteInfo: '',
      profit: 0,
      swanPrice: 0,
      institutionsListA: [
        require('@/assets/img/icon/icon1.png'),
        require('@/assets/img/icon/icon2.png'),
        require('@/assets/img/icon/icon3.png'),
        require('@/assets/img/icon/icon4.png'),
        require('@/assets/img/icon/icon5.png'),
        require('@/assets/img/icon/icon6.png')],
      institutionsListB: [
      require('@/assets/img/slowmist.png'),
        require('@/assets/img/certik.png'),
       
      ],
      institutionsListC: [
        require('@/assets/img/binance.png'),
        require('@/assets/img/okchain.png'),
        require('@/assets/img/trustwallet.png'),
        require('@/assets/img/metamask.png'),
        require('@/assets/img/coinmarketcap.png'),
        require('@/assets/img/bitkeep.png'),
        require('@/assets/img/tokenpocket.png'),
        require('@/assets/img/coingecko.png'),
        require('@/assets/img/linehub.png'),
        require('@/assets/img/klrlfund.png'),

      ],
      institutionsListIcon: [
        require('@/assets/img/IconA.png'),
        require('@/assets/img/IconB.png'),
        require('@/assets/img/IconC.png'),
        require('@/assets/img/IconD.png'),
        require('@/assets/img/IconE.png'),
      ],
      allReword: 0,
      site: '',
      mapShow: false, //发展路线图状态
     
      phoPrice: 0,
      tokenqutoe:[],
      tokenqutoe2:[],
      tokenimgurls:'',
      timer:null,
      
    };
  },

  created () {
    this.init();
 
   
    this.scrollLoad();
    this.scrollLoadmain()
    this.tokenq();
    clearInterval(this.timer);
    this.timer=null;
    this.antimer=null;
  },
  mounted () {
    this.timer=setInterval(this.tokenq,3000);
    // setTimeout(this.yaoqing,1000)
    // this.yaoqing();
    window.addEventListener('scroll',this.scrollLoad,true)
    window.addEventListener('scroll',this.scrollLoadmain,true)
  },
  beforeDestroy () {
  
    clearInterval(this.timer);
    this.timer=null;
    window.removeEventListener("scroll", this.scrollLoad,true);
    window.removeEventListener("scroll", this.scrollLoadmain,true);
  },
  watch: {
    account () {
      this.init();
    },
    lang (){
      this.init()
    }
  },
  
  methods: {
    yaoqing (){
       let url =window.location.href;
       console.log(url);
       let obj = url.split('?invite_code=')[1]//截取问号后面参数
       console.log(obj);
       if(obj!=""&&obj!=null&&obj!=undefined){
        console.log(222);
        let data ={
          parent_addr:obj,
            
            }
        user.postucbind(data,this.account).then(res =>{
          console.log(res);
        })
       }
    },
    bind () {
      let data = { recommend_address: this.site };
      invite.userBind(data, this.account).then(res => {
        if (res.code === 200) {
        } else {
        }
      });
    },
    D6funprice (){
      bizhong.gettokeninfo(this.account).then(res =>{
       this.D6price =res.data.d6_price
       console.log("tr",this.D6price);
      })
    },
    myBalancefun(){
      let data ={
        token_id:5
      }
       moneymask.getwalletgetinfo(data,this.account).then(res =>{
        this.myBalance =res.data.usable
       })
    },
    tokenq (){
    
      homedata.gettokenquote( this.account).then(res =>{
       var a=res.data;
       this.tokenqutoe =a.slice(0,4)
      for(item in this.tokenqutoe){
          if(item.token_id ==5){
             this.D6price =item.last
          }
      }
       this.tokenqutoe2 =this.tokenqutoe.slice(4) ;
       console.log(this.tokenqutoe2);
      })
      homedata.gettokenimgurl().then(res=>{
        this.tokenimgurls=res.data.image_domain;
      })
     
    },
     scrollLoad() {
            // 1.获取所有的box
            var boxList = document.querySelectorAll('.img')
            //2. 定义一个目标值
            var targetValue = window.innerHeight * 0.8
 
            //3.获取每一个box距离浏览器顶部的值
            boxList.forEach(function (box) {
                var boxTop = box.getBoundingClientRect().top;
             
                if (boxTop <= targetValue) {
                    box.classList.add('show-center')
                } else {
                    box.classList.remove('show-center')
                }
            })
 
          
        },
 
        scrollLoadmain() {
            // 1.获取所有的box
            var boxList = document.querySelectorAll('.universal_box')
            //2. 定义一个目标值
            var targetValue = window.innerHeight * 0.8
 
            //3.获取每一个box距离浏览器顶部的值
            boxList.forEach(function (box) {
                var boxTop = box.getBoundingClientRect().top
                
                if (boxTop <= targetValue) {
                    box.classList.add('show-center')
                } else {
                    box.classList.remove('show-center')
                }
            })
 
          
        },
    async init () {
      this.D6funprice()
      this.myBalancefun()
   
     
    },

    

    without () {
      this.$router.push({ name: 'Mining' });
    },
    toSwap () {
      this.$router.push({ name: 'Swap' });
    },
   
    mapclick () {
      this.mapShow = !this.mapShow
    }
  },
  computed: {
    ...mapState(['account','lang']),
  },
  components: { Header2 },
};
</script>

<style lang="less" scoped>
.home_page {
  position: relative;
  background: #0a162dff;
  padding: 0 0 20px;
  overflow-x: hidden;
  margin-top: 30px;
  .gradient {
    font-weight: bold;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .explanation {
    position: relative;
    font-size: 14px;
    // font-weight: bold;
    color: #b8b8b8;
    z-index: 1;
    padding: 30px 20px;
    p{
      color: #fff;
      font-size: 28px;
 font-weight: 500;
 font-family: "HarmonyOSSansSC";
 text-align: left;
 line-height: 30px;
    }
    span{
      color: #5883bfff;
 font-size: 12px;
 font-weight: 500;
 font-family: "HarmonyOSSansSC";
    }
  }
  .route_map {
    position: relative;
    margin: 0 auto;
    width: 334px;

border-radius: 30px;
opacity: 1;
background: #FFFFFF;
    padding: 20px;
    z-index: 1;
    .map {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
      .routemap_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .map_list {
      font-size: 14px;
      color: #848a96;
      p{
        color: #fff !important;
      }
      // font-weight: bold;
      div {
        margin-top: 16px;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      font-weight: bold;
      margin-top: 30px;
      .more_img {
        display: block;
        width: 12px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }
  .exhibit {
    // background: #fff;
    margin: 10px 0 10px;
    padding: 40px 0;
    .hold {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
    }
    .exhibit_li {
   
      display: flex;
      flex-direction: column;
      align-items: center;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      margin-top: 20px;
      
      .li_top {
        font-size: 34px;
        font-weight: bold;
        span {
          background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .li_button {
        font-size: 12px;
        color: #ffffff;
        margin-top: 6px;
      }
    }
   .specialmain{
    height: 120px;
      overflow: hidden;
      margin-top: 40px;
   }
    .special {
     height: 100%;
      display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  

      .special_li {
       
        display: flex;
      justify-content:center;
        align-items:center;
        width: 50%;
        height: 49%;
        position: relative;
        transition: transform .5s ease ;
      .sli_yuan{
        width: 32px;
height: 32px;
  position: absolute;
  left: 30px;
border-radius: 50%;

      }
     .slitext{
      position: absolute;
  right: 40px;
      line-height: 10px;
      .wbnb{
        color: #5883bfff;
 font-size: 12px;
 font-weight: 500;
 font-family: "PingFang";
      }
     }
      }
 
    }
  }
  .universal_box {
    // background: #ffffffff;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    transition: transform .5s ease;
    width: 334px;
    border-radius: 30px ;
    .clcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #c4c4c4;
      font-weight: bold;
      .pledge_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }
    .slogan {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      margin: 10px 0;
    }
    .box_button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40%;
      border-radius: 16px;
      background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
      font-size: 14px;
      color: #ffffff;
      padding: 16px 0;
      margin-top: 20px;
      .box_arrow {
        display: block;
        width: 16px;
        height: 10px;
        margin-left: 8px;
      }
    }
  }
  .universal_box:nth-child(even) {
            transform: translateX(-250%);
        }
 
        /* 奇数 */
        .universal_box:nth-child(odd) {
            transform: translateX(250%);
        }
 
        .universal_box.show-center {
            transform: translateX(0);
        }
  .merchandise_bg {
    background: url('~@/assets/img/merchandise_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .earncoins_bg {
    background: url('~@/assets/img/earncoins_bg.png') no-repeat;
    background-size: 100% 100%;
    .million {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
    }
  }
  .emission_bg {
    background: url('~@/assets/img/emission_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .market_bg {
    background: url('~@/assets/img/market_bg.png') no-repeat;
    background-size: 100% 100%;
  }
  .super_bg {
    background: url('~@/assets/img/super_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .partner_list {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    // border: 1px solid #3399ff;
    padding: 26px 20px 20px;
    margin-top: 40px;
    .partner {
      margin-bottom: 30px;
      .partner_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
        margin-bottom: 20px;
      }
      .partner_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
        .img{
          width: 45%;
          height: 50px;
          border-radius: 16px;
           margin-top: 14px;
           background: #FFFFFF;
         display: flex;
         justify-content: center;
         align-items: center;
         transition: transform .5s ease;
        }
     
 
        /* 偶数 */
        .img:nth-child(even) {
            transform: translateX(-250%);
         
        }
 
        /* 奇数 */
        .img:nth-child(odd) {
            transform: translateX(250%);
          
        }
 
        .img.show-center {
            transform: translateX(0);
           
        }
 
        img{
       width: 80%;
       height: 80%;
        }
        .partner_li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48%;
          height: 52px;
          border-radius: 16px;
          padding: 10px;
          background: #FFFFFF;
          margin-bottom: 14px;
        }
      }
    }
    .reserved {
      color: #ffffffff;
 font-size: 10px;
 font-weight: 500;
 font-family: "HarmonyOSSansSC";
      text-align: center;
    }
    .Icon_img {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      margin-top: 40px;
      .img {
        width: 60px;
      }
    }
  }
}
.inDapp{
  margin: 0 auto;
  width: 336px;
height: 44px;
margin-bottom: 49px;
border-radius: 10px;
opacity: 1;
background: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
color: #ffffffff;
 font-size: 16px;
 font-weight: 700;
 font-family: "PingFang";
text-align: center;
 line-height: 44px;
}
</style>