<template>
  <div style="background: #4583ff; padding-top: 20px">
    <div style="display: flex; align-items: center; position: relative; justify-content: center; width: 100%; margin-bottom: 20px">
      <topbutton router="snowguan" style="position: absolute; left: 20px; top: 30px"></topbutton>
      <div style="margin-top: 30px; font-size: 18px; font-weight: 500; color: #fff">{{$t('lang.d245')}}</div>
      <div></div>
    </div>
    <div class="chobi">
      <div class="chozhi">
        <p class="c_shul">{{ $t('lang.d28') }}</p>
        <van-field type="number" v-model="amount" input-align="left"  :placeholder="`${$t('lang.d247')}`" class="van-field" />
        <div style="font-size: 12px;">
          <p>{{$t('lang.d244')}}: {{ levelInfo.usable }}</p>
        </div>
        <p class="c_shul">{{ $t('lang.d31') }}</p>
        
        <div class="tonzhen ruzhang">
          <span>{{ addressshow }} </span>
       
        </div>

        <div class="zhihui" v-if="showbutton" @click="pledgeredeemFun">{{ $t('lang.d26') }}</div>
        <div class="zhihui" v-else style="opacity: 0.4">{{ $t('lang.d26') }}</div>
      </div>
      <p class="c_jilu">{{ $t('lang.d32') }}</p>
      <div class="c_wlist" v-if="orderlist.length == 0">
        <img src="@/assets/imgsnowball/wujilu.png" alt="" />
        <p>{{ $t('lang.d57') }}</p>
      </div>
      <div class="c_list" v-else>
        <van-list v-model="loading" :finished="finished" :finished-text="`${$t('lang.d145')}`" @load="onLoad" class="list_box">
          <div class="li" v-for="(item, index) in orderlist" :key="index">
            <div>
              <div class="c_head">{{ item.type_txt }}</div>
              <div>{{ item.created_time }}</div>
            </div>
            <span style="color: red"> {{ item.usable_change }}</span>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { zhubi } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
import Web3 from 'web3';
export default {
  data() {
    return {
      orderlist: [],
      amount: '',
      page: 1,
      loading: false,
      finished: false,
      levelInfo:{}
    };
  },
  created() {
    this.init();
  },
  components: {
    topbutton,
  },
  computed: {
    ...mapState(['account', 'defaultaddress', 'lang']),

    showbutton() {
      return !!this.amount;
    },
    addressshow() {
      const head = this.account.slice(0, 4);
      const tail = this.account.slice(this.account.length - 4);
      return head + '****' + tail;
    },
  },
  mounted() {},
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  methods: {
    init() {
      this.pledgerecordlistFun();
      this.getpledgelevelinfo()
    },
    getpledgelevelinfo() {
      zhubi.getpledgelevelinfo(this.account).then(res => {
        console.log(res);
        this.levelInfo = res.data.level_info;
        this.$store.commit('SETLEVELINFO', this.levelInfo);
      });
    },
    pledgerecordlistFun() {
      let data = {
        page: this.page,
        page_size: 10,
        type:0
      };
      zhubi.pledgerecordlist(data, this.account).then(res => {
        if (res.code == 200) {
          this.orderlist = this.orderlist.concat(res.data.list);

          if (res.data.list.length < 10) {
            this.finished = true;
          }
        }
      });
    },
    onLoad() {
      this.page++;
      this.pledgerecordlistFun();
    },
    async pledgeredeemFun() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Redeem'), this.account);
      let data = {
        amount: parseFloat(this.amount),
        signature: sign,
      };
      zhubi.pledgeredeem(data, this.account).then(res => {
        if (res.code == 200) {
          this.init();
        }
        this.$toast(res.msg);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.chobi {
  padding: 20px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
}

.chozhi {
  width: 100%;
  padding: 15px 20px;

  border-radius: 20px;
  background: #f7f7f7;

  .tonzhen {
    width: 100%;
    height: 52px;
    border-radius: 10px;
    background: #ffffff;
    padding: 15px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 10px;
      height: 10px;
    }

    span {
      color: #181818;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }

  .vantonzhenshow {
    padding: 20px;

    .t_li {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #0000000f;

      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        border-radius: 50%;
      }
    }

    .quxiao {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      color: #00000066;
      font-size: 16px;
      font-weight: 500;
      font-family: 'PingFang SC';
    }
  }

  .c_shul {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
    margin-top: 30px;
  }

  .van-field {
    width: 100%;
    border-radius: 10px;
    font-weight: bold;
    background: #ffffff !important;
    padding: 16px 20px !important;

    /deep/ .van-field__control {
      font-size: 18px;
      color: #000;
    }
  }

  .balance {
    color: #9395a4ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .ruzhang {
    position: relative;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .r_line {
      height: 15px;
      width: 1px;
      background: #56a0b7;
      position: absolute;
      right: 60px;
      top: 20px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .c_button {
    margin-top: 30px;
    width: 100%;
    height: 55px;
    border-radius: 10px;
    opacity: 1;
    background: linear-gradient(160deg, #06e2e1ff 0%, #32a0ffff 75%);
    box-shadow: 0 20px 30px 0 #191c321a;
    text-align: center;
    line-height: 55px;
    color: #ffffffff;
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_jilu {
  color: #ffffffff;
  font-size: 18px;
  font-weight: 700;
  font-family: 'PingFang';
  margin-top: 30px;
}

.c_wlist {
  width: 100%;
  height: 219px;
  border-radius: 20px;
  opacity: 1;
  background: #f7f7f7;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 122px;
    height: 92px;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'PingFang';
  }
}

.c_list {
  width: 100%;
  background: #f7f7f7;
  padding: 15px 18px;
  border-radius: 20px;

  .li {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .c_head {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
      }

      .c_time {
        color: #848484;
        font-size: 12px;
        font-weight: 500;
        font-family: 'PingFang';
      }
    }

    span {
      color: #24e7a3;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}

.zhihui {
  margin-top: 30px;
  width: 100%;
  height: 55px;
  border-radius: 65px;
  opacity: 1;
  box-shadow: 0 20px 30px 0 #191c321a;
  text-align: center;
  line-height: 55px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: #4583ff;
}

.op {
  opacity: 0.4;
}
</style>