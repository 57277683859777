<template>
  <div class="fenxiang">
    <topbutton router="kuangchi"></topbutton>
    <div class="imgs">
      <div class="i_img">
        <img src="@/assets/imgsnowball/yaoqinghaoyou.png" alt="" />
        <div class="line"></div>
        <img src="@/assets/imgsnowball/haoyouqidonwakuang.png" alt="" />
        <div class="line"></div>
        <img src="@/assets/imgsnowball/fenxiangshoyi.png" alt="" />
      </div>
    </div>
    <div class="i_text">
      <div>{{ $t('lang.d139') }}</div>
      <div style="margin-left: 30px">{{ $t('lang.d140') }}</div>
      <div>{{ $t('lang.d141') }}</div>
    </div>
    <van-tabs animated class="van_tabs">
      <van-tab :title="`${$t('lang.d142')}`">
        <div class="i_lon" v-if="chelist.length == 0">
          <div class="meiyou">
            <img src="@/assets/img/zanwu.png" alt="" />
            <span>{{ $t('lang.swap365') }}</span>
          </div>
        </div>
        <div class="tab_con" v-else>
          <div style="font-size: 14px; color: #999" class="dis">
            <span>{{ $t('lang.d143') }}</span>
            <span>{{ $t('lang.d144') }}</span>
          </div>

          <van-list v-model="loading" :finished="finished" :finished-text="`${$t('lang.d145')}`" @load="onLoad" class="t_ul">
            <div class="t_li dis" v-for="(item,index) in chelist" :key="index">
              <span>{{ item.address }}</span>
            <!-- <span style="color: #000; font-weight: bold">{{ Fmgval(item.team_level) }}</span> -->

            <span>{{ item.self_pledge }}</span>
            <span style="color: #000">{{ item.team_pledge }}</span>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { user } from '@/api/index';
import topbutton from '@/components/topbutton.vue';
export default {
  name: '',
  components: {
    topbutton,
  },
  data() {
    return {
      chelist: [],
      loading:false,
      finished:false,
      page:1
    };
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getucchildrenlistFun();
    },
    getucchildrenlistFun() {
      let data ={
        page:this.page,
        page_size:10
      }
      user.getucchildrenlist(data,this.account).then(res => {
        if (res.code == 200) {
          this.chelist = this.chelist.concat(res.data);

          if (res.data.length < 10) {
            this.finished = true;
          }
        }
      });
    },
    onLoad() {
      this.page++;
      this.getucchildrenlistFun();
    },
  },
};
</script>

<style lang="less" scoped>
.fenxiang {
  width: 100%;
  background: linear-gradient(180deg, rgba(76, 126, 249, 1) 0%, rgba(150, 208, 255, 1) 100%);
  min-height: 100vh;
  box-sizing: border-box;
  padding: 21px;
  padding-top: 0;
}
.imgs {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
  .i_img {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 42px;
      height: 42px;
    }
    .line {
      border: 2px dotted #ffff;
      width: 50px;
      // height: 1px;
    }
  }
}
.i_text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 500;
  font-family: 'PingFang';
}
.van_tabs {
  background: none;
  margin-top: 20px;
}
/deep/.van-tabs__nav {
  background: none !important;
}
/deep/.van-tabs__line {
  background: #fff !important;
  width: 20px;
  height: 2px;
}
/deep/.van-tab {
  font-size: 14px;
  font-weight: 700;
  font-family: 'PingFang';
}
/deep/.van-tab--active {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: 'PingFang';
}
.i_lon {
  width: 100%;
  height: 261px;
  border-radius: 16px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);

  margin-top: 20px;
  // margin-top: 20px;

  position: relative;
  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 200px;
    }
    span {
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }
  }
}
.tab_con {
  border-radius: 16px;
  opacity: 1;
  background: #fff;

  margin-top: 20px;
  box-sizing: border-box;
  padding: 17px 20px;
  .t_ul {
    min-height: 200px;
    max-height: 400px;
    overflow-y: scroll;
    color: #000;
  }
}
.dis {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>