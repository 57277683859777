<template>
  <div class="kuangchi">
    <!-- <van-popup v-model:show="contentshow" round  >
            <div class="contentshow">
                 <div class="contentshow_title">
                    <span >{{ $t('lang.d153') }}</span>
                    <div @click="contentshow =false">
                        <img src="@/assets/imgsnowball/x_icon.png" style="width: 14px; height: 14px;" alt="">
                    </div>
                  
                 </div>
                 <div class="contentshow_box">
                         <span style="margin-bottom: 43px;">{{ $t('lang.d154') }}</span>
                         <span >{{ $t('lang.d155') }}</span>
                 </div>
            </div>
        </van-popup> -->
    <div class="line1">
      <div class="l1_title">
        <span>KJU-DAO {{$t('lang.d226')}}</span>
        <!-- <span>“</span>
                <img src="@/assets/imgsnowball/webhao_icon.png" style="width: 44px; height: 44px;" alt="" @click="contentshow =true">
                <span>”</span> -->
      </div>
      <div class="l1_lin">
        <div style="color: #666666; font-size: 12px">
          <span> {{$t('lang.d227')}}</span>
          <span> {{$t('lang.d228')}}</span>
        </div>
        <div style="color: #477eff; font-size: 20px">
          <span>{{ sumeyInfo.total_reward }}</span>
          <span>{{ sumeyInfo.yesterday_total_reward }}</span>
        </div>
      </div>
      <div class="l2_lin">
        <div class="l_box">
          <img src="@/assets/imgsnowball/k_qianbao.png" class="img" alt="" />
          <span class="button" style="color: #14f6fe">{{ $t('lang.d129') }}</span>
          <div class="l_main">
            <span style="color: rgba(248, 251, 255, 1); font-style: 14px">
              {{ $t('lang.d130') }}
            </span>
            <span style="color: rgba(69, 131, 255, 1); font-size: 20px"> 0 </span>
          </div>
        </div>
        <div class="l_box">
          <img src="@/assets/imgsnowball/k_gon.png" class="img" alt="" />
          <span class="button" style="color: #fff" @click="tofenxiang">{{ $t('lang.d131') }}</span>
          <div class="l_main">
            <span style="color: rgba(248, 251, 255, 1); font-style: 14px">
              {{ $t('lang.d132') }}
            </span>
            <span style="color: rgba(69, 131, 255, 1); font-size: 20px"> {{ levelInfo.share_num }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="line2">
   
      <div class="kad_l2">
        <img src="@/assets/img/KADlp.png" alt="" style="width: 113px; height: 101px" />
      
      </div>

      <div class="mining" style="margin-top: 35px">
        <div class="mining_head">
          <!-- <div class="head_right2">
            <img src="@/assets/tokenlogo/polygon.png" alt="">
          </div> -->
        </div>
        <div class="content">
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap143') }}</span>
              </div>
              <input type="number"  @input="changeAmount" @blur="blurAmount" @focus="focusAmount"  :placeholder="min_pledgeComusable" v-model="amount" />
            </div>

            <div class="button">
              <van-button type="primary" class="confirmBtn" @click="sendtran" v-if="!SendLoading">
                <span>{{ $t('lang.swap143') }} </span>
              </van-button>
              <div v-else>
                <van-loading color="#1989fa" />
              </div>
            </div>
          </div>
          <div style="width: 100%; text-align: right; color: #000; font-size: 12px; margin-top: 10px">
            <span>KAD {{$t('lang.d230')}}: {{ Web3Banlance }}</span>
          </div>
          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span>{{ $t('lang.swap142') }}</span>
              </div>
              <div>
                <span>{{ levelInfo.reward }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary" class="confirmBtn" @click="pledgeclaimFun">
                <span>{{ $t('lang.swap66') }}</span>
              </van-button>
            </div>
          </div>

          <div class="earned">
            <div class="earned_left">
              <div class="earned_title">
                <span> {{$t('lang.d252')}}</span>
              </div>
              <div style="width: 150px; overflow: hidden">
                <span> {{ levelInfo.usable }}</span>
              </div>
            </div>
            <div class="button">
              <van-button type="primary" class="confirmBtn" @click="dialogshowFun">
                <span> {{$t('lang.d232')}}</span>
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="dialogshow" :closeable="true" round>
         <div class="popup_box">
             <div class="popup_box_title"> {{$t('lang.d232')}}</div>
             <div class="popup_box_content">
                 <div class="popup_box_content_title"> {{$t('lang.d233')}}</div>
                 <div class="popup_box_content_input">
                   <input type="number" v-model="UsbaleAmount"  :placeholder="$t('lang.d233')"> 
                </div>
             </div>
             <div class="popup_box_btn">
                  <div @click="dialogshowFun"> {{$t('lang.d234')}}</div>
                  <div style="color: #5C90FE;" @click="blurUsbaleAmount">{{$t('lang.d237')}}</div>
             </div>
         </div>
    </van-popup>
  </div>
</template>

<script>
import Web3 from 'web3';
import { mapState } from 'vuex';
import { kuangchi, zhubi } from '@/api/index';

export default {
  name: '',
  components: {},
  data() {
    return {
      vgtData: {},
      pledgeconfig: {
        status: '0',
        min_pledge: 0,
        receiver: '',
      },
      levelInfo: {},
      amount: '',
      UsbaleAmount:'',
      SendLoading: false,
      Web3Banlance: 0,
      dialogshow:false,
      sumeyInfo:{
        total_reward:0,
        yesterday_total_reward:0
      }
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
    min_pledgeComusable() {
    if(this.pledgeconfig.min_pledge  - this.levelInfo.usable>0){
      return (this.pledgeconfig.min_pledge  - this.levelInfo.usable) + this.$t('lang.d236');
    }else{
      return this.$t('lang.d247')
    }
    
    },
    min_pledgeComusableNum() {
    if(this.pledgeconfig.min_pledge  - this.levelInfo.usable>0){
      return (this.pledgeconfig.min_pledge  - this.levelInfo.usable);
    }else{
      return 0
    }
    
    },
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      this.getpledgelevelinfo();
      this.pledgeconfigFun();
      this.GetWeb3Banlance();
      this.pledgesummary()
    },
    pledgesummary(){
         zhubi.pledgesummary(this.account).then(res=>{
               this.sumeyInfo = res.data
         })
    },
    dialogshowFun (){
      this.dialogshow = ! this.dialogshow 
    },
    getpledgelevelinfo() {
      zhubi.getpledgelevelinfo(this.account).then(res => {
        console.log(res);
        this.levelInfo = res.data.level_info;
        this.$store.commit('SETLEVELINFO', this.levelInfo);
      });
    },
    tofenxiang() {
      this.$router.push({ name: 'fenxiang' });
    },
    changeAmount() {
      //  if(parseFloat(this.amount)<100){
      //       this.amount = 100
      //  }
    },
    async pledgeredeemFun() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Redeem'), this.account);
      let data = {
        amount: parseInt(this.UsbaleAmount),
        signature: sign,
      };
      zhubi.pledgeredeem(data, this.account).then(res => {
        if (res.code == 200) {
          this.init();
          this.dialogshow =false
        }else{

        }
        this.$toast(res.msg);
      });
    },
    focusAmount() {},
    blurAmount() {
      if (parseInt(this.amount) < this.min_pledgeComusableNum) {
        this.amount = parseInt(this.min_pledgeComusableNum);
        this.$toast(this.min_pledgeComusable);
      }else{
     
      }
    },
    blurUsbaleAmount() {
     
      
        this.pledgeredeemFun()
     
    },
    pledgeconfigFun() {
      zhubi.pledgeconfig(this.account).then(res => {
        if (res.code == 200) {
          this.pledgeconfig = res.data;
        }
      });
    },
    async GetWeb3Banlance() {
      let web3 = new Web3(window.ethereum);
      let balance = await web3.eth.getBalance(this.account);
      balance = web3.utils.fromWei(balance, 'ether');
      this.Web3Banlance = parseFloat(balance).toFixed(2);
    },
    approveVgt() {},
    selectVgt() {},
    async pledgeclaimFun() {
      let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Reward Claim'), this.account);
      let data = {
        amount: this.levelInfo.reward,
        signature: sign,
      };
      zhubi.pledgeclaim(data, this.account).then(res => {
        if (res.code == 200) {
          this.init();
        }
        this.$toast(res.msg);
      });
    },
    async sendtran() {
      if (this.pledgeconfig.status == '0') {
        this.$toast(this.$t('lang.d246'));
        return;
      }
      console.log(this.amount,'this.amount');
      if (this.amount == '') {
        this.$toast(this.$t('lang.d247'));
        return;
      }
      if (parseFloat(this.amount)>this.Web3Banlance) {
        this.$toast(this.$t('lang.swap11'));
        return;
      }
      if (this.min_pledgeComusableNum > this.amount) {
        this.$toast(this.min_pledgeComusable);
        return;
      }
      this.SendLoading = true;
      let web3 = new Web3(window.ethereum);
      const gasPrice = await web3.eth.getGasPrice();
      this.amount =  String(this.amount)
      console.log(web3.utils.toWei(this.amount, 'ether'));
      web3.eth
        .sendTransaction({
          from: this.account,
          to: this.pledgeconfig.receiver,
          value: web3.utils.toWei(this.amount, 'ether'),
        })
        .on('receipt', res => {
          this.SendLoading = false;
          this.pledgeentrymarkFun(res.transactionHash);
        })
        .on('transactionHash', res => {
          this.$toast(this.$t('lang.d253'));
          this.SendLoading = false;
        })
        .on('error', err => {
          this.$toast(this.$t('lang.d249'));
          this.SendLoading = false;
        });
    },
    pledgeentrymarkFun(hash) {
      zhubi.pledgeentrymark({ tx_hash: hash }, this.account).then(res => {
        if (res.code == 200) {
          this.init();
        }
        this.$toast(res.msg);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup_box{
  width: 288px;

.popup_box_btn{
  display: flex;
  border-top: 1px solid rgba(240, 240, 240, 1);
  &>div{
    width: 50%;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
  }
  &>div:nth-child(1){
    border-right: 1px solid rgba(240, 240, 240, 1);
  }
}
.popup_box_title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  width: 100%;
  color: #000;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}
.popup_box_content{
  padding: 16px 20px;
  margin-bottom: 23px;
  .popup_box_content_title{
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
  }
  .popup_box_content_input{
    width: 100%;
    height: 48px;
    background: #F0F0F0;
    border-radius: 84px;
    padding: 14px 24px;
    margin-top: 12px;
      input{
        width: 100%;
        border: none;
        background: none;
        color: #000;
        font-weight: bold;
       
      }
  }
}
}
.contentshow {
  width: 335px;
  .contentshow_title {
    padding: 20px;
    background: linear-gradient(180deg, rgba(232, 239, 255, 1) 0%, rgba(232, 239, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 21px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contentshow_box {
    padding: 20px;
    padding-bottom: 40px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(34, 34, 34, 1);
    display: flex;
    flex-direction: column;
  }
}
.kuangchi {
  width: 100%;
  background: linear-gradient(180deg, rgba(76, 126, 249, 1) 0%, rgba(150, 208, 255, 1) 100%);

  .line1 {
    width: 100%;
    padding: 20px;

    .l1_title {
      color: rgba(255, 255, 255, 1);
      width: 100%;
      font-size: 28px;
      font-weight: 700;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .l1_lin {
      height: 95px;
      border-radius: 16px;
      opacity: 1;
      background: #26272b;
      background: url('~@/assets/img/l1_lin.png') no-repeat;
      background-size: cover;
      padding: 20px 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .l2_lin {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 111px;
      margin-top: 55px;

      .l_box {
        width: 48%;
        height: 100%;
        position: relative;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 1);
        opacity: 1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 1) 99.31%);

        .img {
          width: 128.13px;
          height: 128.13px;

          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .button {
          position: absolute;
          right: 8px;
          top: 8px;
          font-size: 14px;
          font-weight: 500;
          font-family: 'PingFang';
        }
        .l_main {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 100%);
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .line2 {
    border-radius: 30px 30px 0 0;
    opacity: 1;
    background: #fff url('~@/assets/img/kuang_zhiya.png') no-repeat;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .l2_title {
      color: rgba(5, 5, 5, 1);

      font-size: 20px;
      font-weight: 700;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
    }
    .kad_l2 {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center;
      .kad_l2_box {
        font-size: 28px;
        font-weight: bold;
        color: #050505;
        display: flex;
        align-items: center;
      }
    }
    .mining {
      .mining_head {
        display: flex;
        justify-content: space-between;
        // box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);

        .head_right2 {
          padding: 5px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          border: 1px solid #d4d4d4;
          img {
            border-radius: 50%;
            width: 34px;
            height: 34px;
          }
        }
        .head_left {
          display: flex;
          // align-items: center;
          font-size: 18px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          padding: 10px 0;
          .head_img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            transform: translate(0%, -10%);
          }
          .special_img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            transform: translate(-40%, 10%);
          }
        }
        .head_right {
          display: flex;
          padding-top: 10px;
          .exhibition {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            color: #666666;
            margin-right: 40px;
            .value {
              color: #333333;
              margin-top: 6px;
            }
          }
        }
      }
      .content {
        .earned {
          display: flex;
          justify-content: space-between;
          background: rgba(241, 244, 249, 1);
          border-radius: 10px;
          padding: 20px;
          margin-top: 20px;
          input {
            border: none;
            background: none;
            color: #000;
            font-weight: bold;
          }
          .earned_left {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            .earned_title {
              font-size: 12px;
              color: #666666;
              margin-bottom: 10px;
            }
          }
          .button {
            display: flex;
            align-items: flex-end;
            .confirmBtn {
              height: auto;
              font-size: 16px;
              font-family: Source Han Sans CN-Bold, Source Han Sans CN;
              font-weight: bold;
              color: #333333;
              border-radius: 16px;
              padding: 4px 20px;
            }
            .button_img {
              width: 26px;
              height: 26px;
              margin-left: 10px;
            }
          }
        }
        .staked {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #f8f7f7;
          border-radius: 10px;
          padding: 10px 20px;
          margin-top: 20px;
          .staked_title {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
          .confirmBtn {
            height: auto;
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            border-radius: 16px;
            padding: 4px 59px;
            margin-top: 20px;
          }
        }
        .tvl {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-family: Segoe UI-Bold, Segoe UI;
          font-weight: bold;
          color: #333333;
          background: #ffffff;
          border: 1px solid #3399ff;
          // box-shadow: 0px 1px 2px 1px #3399ff;
          border-radius: 10px;
          padding: 6px 12px;
          margin-top: 12px;
          div {
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
          .money_img {
            width: 16px;
            height: 16px;
          }
        }

        .specific_data {
          background: #ffffff;
          border-radius: 10px;
          margin-top: 16px;
          .specific {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 10px;
            font-family: Segoe UI-Bold, Segoe UI;
            font-weight: bold;
            color: #333333;
            margin-top: 10px;
            .special {
              color: #3399ff;
            }
            .company {
              margin-left: 6px;
            }
          }
        }
      }
      .down {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 20px;
      }
    }
  }
}
</style>