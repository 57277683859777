<template>
  <div class="doExchange">
    <div class="title" style="margin-bottom: 10px;">{{ $t('lang.b17') }}</div>
    <el-select v-model="exchangeItem" :placeholder="$t('lang.b21')" @change="change" style="width: 100%; height: 58px; border-radius: 14px">
      <el-option v-for="item in exchangeList" :key="item.value" :label="item.value + '' + item.name" :value="item" style="display: flex; align-items: center; justify-content: space-between">
        <span>{{ item.value }}</span>
        <div style="display: flex; align-items: center">
          <img :src="item.logo" alt="" style="width: 20px; height: 20px; border-radius: 50%; margin-right: 10px" />
          <span style="float: left">{{ item.name }}</span>
        </div>
      </el-option>
    </el-select>
    <div class="title" style="margin-top: 24px; margin-bottom: 12px;">{{ $t('lang.b18') }}</div>
    <div class="text_num">{{exchangeNum}}BEBS</div>
    <el-button type="primary" style="width: 100%; border-radius: 10px; height: 50px; background: #25BCFF;" @click="doExchangeFun" v-if="allowance!=0">{{ $t('lang.b19') }}</el-button>
    <el-button type="primary" style="width: 100%; border-radius: 10px; height: 50px; background: #25BCFF;" @click="approveFun" v-else>{{ $t('lang.b20') }}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    exchangeList: {
      type: Array,
      default() {
        return [];
    },
  
  },
  allowance:{
      type:String,
      default:"0"
    }
  },
  data() {
    return {
      exchangeItem: {},
      exchangeNum: 0,
      
    };
  },
  components: {},
  mounted() {},
  created() {
             this.init()
  },
  methods: {
    init() {
      
    },
    change(val) {
     this.exchangeNum = this.exchangeItem.exchangeNum
     console.log('this.exchangeItem',this.exchangeItem);
     this.$emit('change',this.exchangeItem)
    },
    doExchangeFun(){
        this.$emit('doExchange')
    },
    approveFun(){
      this.$emit('approve')
    }
  },
};
</script>
<style scoped lang='less'>
/deep/.el-input__inner{
    background: #F2F2F2;
    border: none;
    padding: 25px 15px !important;
    box-sizing: border-box;
    border-radius: 14px;
    font-size: 14px;
    color: #222222;
}
.doExchange {
  padding: 19px 17px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #e2e2e2;
  .title{
    font-size: 16px;
font-weight: bold;
color: #3D3D3D;
  }
  .text_num{
    margin-bottom: 22px;
    background: linear-gradient(180deg, #2F2F2F 30%, #8A8479 50%, #1E1E1E 68%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
  }
}
</style>